import logo from './logo.svg';
import './App.css';
import React, {useEffect, useState} from 'react';
import bgImage from './bg.png';
import LanguageDropdown from './LanguageDropdown';


const Placeholder = () => {
  const [isEnglish, setIsEnglish] = useState(false);
  const [textDisplay, setTextDisplay] = useState('');
  const [showCursor, setShowCursor] = useState(true);

  const englishText = 'We are taking our best efforts towards the optimal experience...';
  const chineseText = '请稍等，我们正努力为您带来最佳阅读体验...'

  const handleToggleLanguage = (language) => {
    setIsEnglish(language);
    setTextDisplay('');
  };

  useEffect(() => {
    const text = isEnglish ? englishText : chineseText;
    let index = 0;
    const interval = setInterval(() => {
      if (index < text.length) {
        setTextDisplay(prev => prev + text[index]);
        index += 1;
      } else {
        clearInterval(interval);
        setShowCursor(true);
      }
    }, 100);
    return () => clearInterval(interval);
  }, [isEnglish]);

  return (
    <div className='placeholder' style={{ backgroundImage: `url(${bgImage})` }}>
      <LanguageDropdown isEnglish={isEnglish} onLanguageChange={handleToggleLanguage} />
      <div className='text-box'>
      <p>{isEnglish ? "Coming soon" : "网站建设中"}</p>
        <p className="blinking">
          {isEnglish
            ? "We are taking our best efforts towards the optimal experiences..."
            : "请稍等，我们正努力为您带来最佳阅读体验......"}
        </p>
      </div>
    </div>
  );
};

export default Placeholder;
