import React, { useState, useRef, useEffect} from "react";
import './LanguageDropdown.css';

const LanguageDropdown = ({ isEnglish, onLanguageChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
  
    const toggleDropdown = () => setIsOpen(!isOpen);
  
    const handleLanguageSelect = (language) => {
      onLanguageChange(language);
      setIsOpen(false); // Close dropdown after selection
    };
  
    // Close dropdown if clicking outside
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);
  
    return (
      <div className="language-dropdown" ref={dropdownRef}>
        <button onClick={toggleDropdown} className="dropdown-toggle">
          {isEnglish ? 'English' : '中文'}
        </button>
        {isOpen && (
          <div className="dropdown-menu">
            <div
              className={`dropdown-item ${!isEnglish ? 'active' : ''}`}
              onClick={() => handleLanguageSelect(false)}
            >
              中文
            </div>
            <div
              className={`dropdown-item ${isEnglish ? 'active' : ''}`}
              onClick={() => handleLanguageSelect(true)}
            >
              English
            </div>
          </div>
        )}
      </div>
    );
  };
  
  export default LanguageDropdown;